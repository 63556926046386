@use "../../styles/colors.scss";
@import "react-toastify/dist/ReactToastify.css";

.toast {

  &--success {
    background-color: colors.$success !important;
  }

  &--error {
    background-color: colors.$error !important;
  }

  &--warn {
    background-color: colors.$warn !important;
  }
}
