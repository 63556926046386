@use "../../styles/colors.scss";

.input {
  height: 40px;
  border-width: 1px;
  border-radius: 10px;
  padding: 5px 15px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  text-overflow: ellipsis;

  &:focus,
  &:active {
    outline-color: colors.$primary;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 5px;
  }

  &__error-wrapper {
    min-height: 20px;
    margin-bottom: 5px;
  }

  &__error {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: colors.$textError;
    margin-top: 1px;
  }
}
