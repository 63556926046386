.delete-dialog-content {
  max-width: 380px;

  &__message {
    text-align: center;
  }

  &__buttons {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    & > * {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
