.table-action-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;

  & > * {
    margin-left: 15px;
  }
}
