@use '../../styles/colors.scss';

.hcp-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 25px;
    color: colors.$secondary;
  }

  & > button {
    align-self: flex-end;
  }
}
