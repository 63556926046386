@use "../../styles/colors.scss";

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background-color: colors.$white;
  z-index: 11;
  border-radius: 10px;
  padding: 40px 35px;

  &--dialog {
    width: 450px;
  }

  &__wrapper {
    background-color: rgba(colors.$secondary, 0.8);
    width: 100vw;
    height: 100vh;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
  }

  & > button {
    position: absolute;
    top: 25px;
    right: 25px;
    background-color: colors.$primary;
    border: none;
    border-radius: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: colors.$primaryHover;
    }
  }
}
