@use "../../../../styles/colors.scss";

.table-action-button {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &--edit {
    background-color: colors.$primary;

    &:hover {
      background-color: colors.$primaryHover;
    }
  }
  &--delete {
    background-color: colors.$error;

    &:hover {
      background-color: colors.$errorHover;
    }
  }

  &:disabled {
    background-color: colors.$backgroundDisabled;
    cursor: not-allowed;

    & > img {
      filter: invert(30%) sepia(0%) saturate(154%) hue-rotate(192deg)
        brightness(93%) contrast(83%);
    }
  }
}
