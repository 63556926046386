@use "../../styles/colors.scss";

.router {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &--with-nav {
    top: -18px;
  }

  &__navigation {
    display: flex;
    flex-direction: row;
    top: 18px;
    position: relative;
    z-index: 1;
    flex: 0 1 auto;
  }

  &__navlink {
    text-decoration: none;
    color: colors.$textInactive;

    & > div {
      background-color: colors.$section;
      min-width: 300px;
      padding: 25px;
      border-radius: 20px 20px 0 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      height: 85px;
    }

    &--active {
      & > div {
        color: colors.$textActive;
        background-color: white;
      }
    }

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  &__content {
    background-color: white;
    border-radius: 20px;
    padding: 35px;
    height: 100%;
    position: relative;
    z-index: 2;
    flex: 1 1 auto;
    display: flex;
  }
}
