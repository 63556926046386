@use "../../styles/colors.scss";

.table {
  border-spacing: 0 5px;
  border-collapse: "separate";
  width: 100%;

  &__head {
    background-color: colors.$third;
    color: colors.$white;
    padding: 15px 25px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: left;

    &:first-child {
      border-radius: 10px 0 0 10px;
    }

    &:last-child {
      border-radius: 0 10px 10px 0;
    }
  }

  &__row {
    &:nth-child(even) {
      background-color: colors.$section;
    }

    &:nth-child(odd) {
      background-color: colors.$sectionAccent;
    }
  }

  &__data {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 0 25px;
    max-width: 400px;
    overflow: hidden;
    word-break: break-all;

    &:first-child {
      border-radius: 10px 0 0 10px;
    }

    &:nth-last-child(2) {
      border-radius: 0 10px 10px 0;
    }

    &:last-child {
      background-color: colors.$white;
      width: 130px;
      padding: 0;
    }
  }
}

.paginator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  &__button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    & > img {
      width: 30px;
      height: 30px;
      filter: invert(33%) sepia(99%) saturate(568%) hue-rotate(158deg)
        brightness(93%) contrast(91%);
    }

    &:hover {
      & > img {
        filter: invert(15%) sepia(71%) saturate(3620%) hue-rotate(181deg)
          brightness(88%) contrast(94%);
      }
    }

    &:disabled {
      cursor: not-allowed;

      & > img {
        filter: invert(81%) sepia(8%) saturate(21%) hue-rotate(331deg)
          brightness(86%) contrast(90%);
      }
    }
  }
}
