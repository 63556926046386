$primary: #1276a0;
$primaryHover: #07506d;
$secondary: #005197;
$third: #125c7b;
$textActive: #3b83bd;
$textDisabled: #b0b0b0;
$textInactive: #bfcde0;
$textError: #f03658;
$background: #c3cadc;
$backgroundDisabled: #e4e4e4;
$section: #f8fafb;
$sectionAccent: #f0f7fa;
$white: #ffffff;
$error: #f87f7f;
$errorHover: #ec5151;
$success: #68c677;
$warn: #f0ba69;
