@use "../../styles/colors.scss";

.button {
  background-color: colors.$primary;
  cursor: pointer;
  color: colors.$white;
  padding: 10px 25px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  border-radius: 10px;
  border: none;

  &:hover {
    background-color: colors.$primaryHover;
  }

  &--delete {
    background-color: colors.$error;

    &:hover {
      background-color: colors.$errorHover;
    }
  }
  &:disabled {
    cursor: not-allowed;
    background-color: colors.$backgroundDisabled;
    color: colors.$textDisabled;
  }
}
