@use "../../styles/colors.scss";

.loader {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: colors.$primary;
  color: colors.$primary;
  animation: loader 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    left: -30px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: colors.$primary;
    color: colors.$primary;
    animation: loader 1s infinite alternate;
    animation-delay: 0s;
  }
  &::after {
    left: 30px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: colors.$primary;
    color: colors.$primary;
    animation: loader 1s infinite alternate;
    animation-delay: 1s;
  }

  &__wrapper {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
  }
}

@keyframes loader {
  0% {
    background-color: colors.$primary;
  }
  50%,
  100% {
    background-color: rgba(colors.$primary, 0.2);
  }
}
