.institution-admin-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    & > button {
      align-self: flex-end;
    }
  }
  