@use "../../styles/colors.scss";
@import "@aws-amplify/ui-react/styles.css";

[data-amplify-authenticator] {
  background-image: url("../../../public/Background.png");
  height: 100vh;
}

.authenticator {
  &__header {
    color: colors.$white;
    background-color: colors.$secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0em;
    border-radius: 15px 15px 0 0;
    padding: 10px;
  }

  &__logo {
    margin-right: 10px;
  }
}

.amplify-button[data-variation="primary"] {
  background-color: colors.$secondary;

  &:hover {
    background-color: rgba(colors.$secondary, 0.8);
  }
}

.amplify-button[data-variation="link"] {
  color: colors.$secondary;

  &:hover {
    background-color: rgba(colors.$secondary, 0.1);
  }
}

[data-amplify-router] {
  border-radius: 0 0 15px 15px;
}

.amplify-input {
  &:focus {
    border-color: colors.$primary;
  }
}

.amplify-field__show-password {
  &:focus {
    border-color: colors.$primary;
  }
}
