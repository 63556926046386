@use "../../styles/colors.scss";

.page-layout {
  background-color: colors.$background;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  min-height: 100vh;

  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
    flex: 0 1 auto;
  }

  &__logo-wrapper {
    background-color: colors.$white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    border-radius: 0px 0px 20px 20px;
  }

  &__logo {
    width: 30px;
    height: 25px;
  }

  &__app-name {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: colors.$secondary;
    margin-left: 25px;
  }

  &__profile-section {
    display: flex;
    flex-direction: row;
  }

  &__profile-info-wrapper, &__institution-info-wrapper {
    background-color: colors.$white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    border-radius: 0px 0px 20px 20px;
  }

  &__institution-info-wrapper {
    margin-right: 20px;
  }

  &__institution-label {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  &__institution-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  &__profile-info, &__institution-info {
    margin-right: 20px;
    & > div {
      text-align: left;
    }
  }

  &__user-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  &__user-role {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  &__sign-out-button {
    border-radius: 0px 0px 20px 20px;
    margin-left: 20px;
    width: 100px;
    background-color: colors.$white;
    padding: 0;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: colors.$sectionAccent;
    }
  }
}
