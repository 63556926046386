.form {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    max-width: 600px;
  
    &__header {
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
  
      & > img {
        margin-right: 20px;
      }
    }
  
    &__row {
      display: flex;
      flex-direction: row;
      width: 100%;
  
      &--short {
        width: 50%;
        padding-right: 15px;
      }
  
      & > * {
        &:not(:first-child) {
          margin-left: 30px;
        }
      }
    }
  
    &__button {
      margin-top: 100px;
    }
  }
  